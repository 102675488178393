@import "@gumtree/ui-library/src/base/color-variables.scss";
@import "@gumtree/ui-library/src/base/boxes.scss";
@import "@gumtree/ui-library/src/base/grid.scss";
@import "@gumtree/ui-library/src/base/fonts.scss";

.side-menu-link {
    color: $color-font--secondary;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: $box-height;
    padding: $gutter-size--medium $gutter-size--xlarge;

    .icon {
        margin-right: $gutter-size--medium;
    }

    .has-beta-label {
        background: $green;
        padding: 2px $gutter-size;
        border-radius: 18px;
        font-size: $font-size--xsmall;
    }

    &:hover {
        background-color: $color-background--medium;
    }

    @media (hover: none) {
        &:hover {
            background-color: transparent;
        }
    }
}

@import "../base/grid.scss";

.grid {
    box-sizing: border-box;
}

.grid--container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (-$gutter-size);

    &.grid--item {
        margin: 0 (-$gutter-size);
    }
}

.grid--item {
    flex: 1;
    padding: 0 $gutter-size;
}

.grid--gutter-none {
    padding: 0;
}

.grid--gutter-small {
    padding: 0 $gutter-size--small;
}

.grid--gutter-medium {
    padding: 0 $gutter-size--medium;
}

.grid--gutter-large {
    padding: 0 $gutter-size--large;
}

[class*="grid--col-"] {
    flex: none;
    width: 100%;
}

@for $i from 1 through 12 {
    .grid--col-#{$i} {
        width: #{100% / 12 * $i};
    }
}

@for $i from 0 through 12 {
    .grid--col-offset-#{$i} {
        margin-left: #{100% / 12 * $i};
    }
}

.grid--col-offset-0 {
    margin-left: 0;
}

@include mq($from: xs) {
    @for $i from 1 through 12 {
        .grid--col-#{$i}-s {
            width: #{100% / 12 * $i};
        }
    }

    @for $i from 0 through 12 {
        .grid--col-offset-#{$i}-s {
            margin-left: #{100% / 12 * $i};
        }
    }
}

@include mq($from: s) {
    @for $i from 1 through 12 {
        .grid--col-#{$i}-m {
            width: #{100% / 12 * $i};
        }
    }

    @for $i from 0 through 12 {
        .grid--col-offset-#{$i}-m {
            margin-left: #{100% / 12 * $i};
        }
    }
}

@include mq($from: m) {
    @for $i from 1 through 12 {
        .grid--col-#{$i}-l {
            width: #{100% / 12 * $i};
        }
    }
    @for $i from 0 through 12 {
        .grid--col-offset-#{$i}-l {
            margin-left: #{100% / 12 * $i};
        }
    }
}

@include mq($from: l) {
    @for $i from 1 through 12 {
        .grid--col-#{$i}-xl {
            width: #{100% / 12 * $i};
        }
    }

    @for $i from 0 through 12 {
        .grid--col-offset-#{$i}-xl {
            margin-left: #{100% / 12 * $i};
        }
    }
}

@include mq($from: xl) {
    @for $i from 1 through 12 {
        .grid--col-#{$i}-xxl {
            width: #{100% / 12 * $i};
        }
    }

    @for $i from 0 through 12 {
        .grid--col-offset-#{$i}-xxl {
            margin-left: #{100% / 12 * $i};
        }
    }
}
//

@include mq($until: xs) {
    .grid--hidden-until-s {
        display: none;
    }
}

@include mq($until: s) {
    .grid--hidden-until-m {
        display: none;
    }
}

@include mq($until: m) {
    .grid--hidden-until-l {
        display: none;
    }
}

@include mq($until: l) {
    .grid--hidden-until-xl {
        display: none;
    }
}

@include mq($until: xl) {
    .grid--hidden-until-xxl {
        display: none;
    }
}

@import "@gumtree/ui-library/src/base/grid.scss";

.link-button {
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
        margin-right: $gutter-size;
    }

    &.padded {
        padding: $gutter-size--medium;
    }
}

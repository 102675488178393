@import "../../base/color-variables.scss";
@import "../../base/boxes.scss";
@import "../../base/z-indexes.scss";
@import "../../base/grid.scss";

.type-ahead-options {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: $box-height;
    min-width: 100%;
    z-index: $z-index--medium;
    background-color: $color-background--neutral;
    border: $box-border-width solid $color-border--light;
    border-radius: 4px;
    box-shadow: 0 1px 8px $color-shadow--light;
    display: none;

    @include mq($until: m) {
        margin-top: 5px;
    }
    @include mq($from: m) {
        margin-top: $gutter-size;
    }

    &.type-ahead-options--open {
        display: block;
    }
}

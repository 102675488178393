@mixin slide-in() {
    will-change: auto;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    transform: translateY(-10px);
    transition: all .4s ease;

    &.open {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}

@mixin collapsable($height) {
    will-change: auto;
    height: $height;
    transition: height .5s ease;

    &.closed {
        height: 0;
    }
}

@import "../../base/color-variables.scss";
@import "../../base/boxes.scss";
@import "../../base/grid.scss";
@import "../../base/fonts.scss";

.type-ahead-limit {
    display: flex;
    align-items: center;

    .icon {
        padding-right: $gutter-size--small;
    }
}

.type-ahead-option {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: auto;
    white-space: pre-wrap;
    padding: $gutter-size--medium $gutter-size--large;

    &.type-ahead-option--focused,
    &:hover {
        background-color: $color-background--medium;
    }

    & + & {
        border-top: none;
    }

    &:last-of-type {
        padding-bottom: 10px;
    }

    .icon-part-container {
        position: relative;
        width: 24px;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .button--only-icon {
        background-color: transparent;
        border: none;
    }

    .text-part-container {
        margin-left: $gutter-size--medium;
    }

    button {
        color: $bark;
        width: 18px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: auto !important;

        &:hover {
            color: $bark--dark;
        }

        .icon--clear {
            font-size: 14px;
        }
    }
}

.type-ahead-option-primary-text {
    font-size: 15px;
    font-weight: $font-weight--light;
    line-height: 24px;

    &.type-ahead-option-highlight,
    & .type-ahead-option-highlight {
        font-weight: $font-weight--bolder;
    }
}

.type-ahead-option-secondary-text {
    color: $gunmetal-blue;
    font-size: 13px;
    font-weight: $font-weight--light;
    line-height: 20px;
}

@import "../base/fonts.scss";
@import "./inter-google-font.scss";

%font-bold {
    font-weight: $font-weight--bold;
}

$font-family-unloaded: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, "Lucida Grande", sans-serif;
$font-family: "Inter", $font-family-unloaded;

body {
    font-family: $font-family;
    font-size: $font-size--base;
    line-height: 22px;
    font-weight: $font-weight--normal;
    color: #3c3241;
    letter-spacing: 0.01em;
    margin: 0;
}

p {
    margin-bottom: 18px;
}

strong {
    @extend %font-bold;
}

small {
    font-size: $font-size--small;
    letter-spacing: 0.3px;
}

%h {
    @extend %font-bold;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 18px;
    color: #3c3241;
    letter-spacing: 0.01em;
}

.h1,
h1 {
    @extend %h;
    font-size: $font-size--xlarge;
    line-height: 32px;
}

.h2,
h2 {
    @extend %h;
    font-size: $font-size--large;
    line-height: 28px;
}

.h3,
h3 {
    @extend %h;
    font-size: $font-size--medium;
    line-height: 26px;
}

.h4,
h4 {
    @extend %h;
    font-size: $font-size--base;
    line-height: 22px;
}

button {
    font: inherit;
}

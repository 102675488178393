@import "../base/grid.scss";

.container {
    margin: 0 auto;
    padding: 0 $gutter-size;

    @include mq($from: l) {
        &:not(.container--no-l) {
            max-width: 1024px;
        }
    }

    @include mq($from: xl) {
        &:not(.container--no-xl) {
            max-width: 1280px;
        }
    }

    @include mq($from: xxl) {
        &:not(.container--no-xxl) {
            max-width: 1350px;
        }
    }

    &.section {
        padding-bottom: $gutter-size--medium;
        padding-top: $gutter-size--medium;

        @include mq($from: m) {
            padding-bottom: $gutter-size--xlarge;
            padding-top: $gutter-size--xlarge;
        }

        .container & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@import "../base/color-variables.scss";
@import "../base/grid.scss";

.toggle {
    .content {
        display: none;

        &.transition {
            display: block;
            height: 0;
            opacity: 0;
            overflow: hidden;
        }
    }

    &.open {
        .content {
            display: block;
        }

        &.open-transition {
            .content {
                height: auto;
                opacity: 1;
                transition: opacity 0.5s ease;
            }
        }
    }
}

.toggler {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    background: none;
    border: none;

    .icon {
        transition: transform 0.5s ease;

        .toggle.open & {
            transform: rotateX(180deg);
        }
    }

    .icon-text:before {
        font-family: "Inter", Arial, sans-serif;
        content: "Show more";
        color: $color-main-tertiary;
        font-size: 11px;
        padding-right: $gutter-size--small;

        .toggle.open & {
            content: "Show less";
        }
    }

    .inline & {
        display: block;

        :first-child {
            display: inline-block;
        }
    }
}

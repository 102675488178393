@import "../base/color-variables.scss";
@import "../base/grid.scss";
@import "../base/fonts.scss";

.link-list {
    text-align: center;

    .item {
        display: inline-block;
        overflow: hidden;

        &:not(:last-child):after {
            content: "|";
            font-weight: $font-weight--bolder;
            color: $color-text--light;
        }
    }

    .link {
        padding: $gutter-size;
    }
}

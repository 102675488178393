@import "../base/color-variables.scss";
@import "../base/fonts.scss";

.link {
    color: $color-text--link;
    cursor: pointer;
    text-decoration: none;
    font-size: $font-size--medium;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

@import "@gumtree/ui-library/src/base/grid.scss";

$logo-image--primary-horizontal: 40px;
$logo-image--primary-horizontal--small: 25px;
$logo-image--primary-vertical: 41px;
$logo-image--primary-vertical--small: 46px;
$logo-text--primary-horizontal: 19px;
$logo-text--primary-horizontal--small: 18px;
$logo-text--primary-vertical: 22px;
$logo-text--primary-vertical--small: 13px;
$logo-text-ratio: 6;

.logo {
    display: flex;
    white-space: nowrap;

    &.logo--vertical {
        flex-direction: column;
    }
}

.logo-text {
    display: flex;
    margin-left: 12px;

    svg {
        width: $logo-text--primary-horizontal * $logo-text-ratio;
        height: auto;
    }

    .logo--small & {
        width: $logo-text--primary-horizontal--small * $logo-text-ratio;
    }

    .logo--vertical & {
        margin-top: 6px;
        margin-left: 0;
        width: $logo-text--primary-vertical * $logo-text-ratio;
    }

    .logo--small.logo--vertical & {
        width: $logo-text--primary-vertical--small * $logo-text-ratio;
    }
}

.logo-image {
    display: flex;

    svg {
        width: auto;
        height: 35px;

        @include mq($from: s) {
            height: $logo-image--primary-horizontal;
        }

        .logo--small & {
            height: $logo-image--primary-horizontal--small;
        }

        .logo--vertical & {
            margin: 0 auto;
            height: $logo-image--primary-vertical;
        }

        .logo--small.logo--vertical & {
            height: $logo-image--primary-vertical--small;
        }
    }
}

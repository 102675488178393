@import "@gumtree/ui-library/src/base/color-variables.scss";
@import "@gumtree/ui-library/src/base/z-indexes.scss";

.side-nav-box {
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: initial;
    overflow-y: auto;
    height: 100%;
    width: auto;
    background-color: $color-text--neutral;
    z-index: $z-index--high;

    .side-menu-link {
        justify-content: space-between;
        color: $white;
        flex-direction: row-reverse;

        &:hover {
            color: $bark--80;
        }

        .icon {
            margin-right: 0;
        }
    }

    .side-menu-title {
        display: none;
    }
}

.side-nav-box--right {
    right: 0;
    left: auto;
    z-index: $z-index--high + 2;
}

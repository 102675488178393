@import "@gumtree/ui-library/src/base/z-indexes.scss";
@import "@gumtree/ui-library/src/base/mixins/transitions.scss";
@import "@gumtree/ui-library/src/base/colors.scss";
@import "@gumtree/ui-library/src/base/grid.scss";
@import "@gumtree/ui-library/src/base/fonts.scss";

.user-sub-menu {
    @include slide-in();

    &.open {
        z-index: $z-index--high * 2;
    }

    display: block;
    z-index: $z-index--low;
    font-size: $font-size--small;
    position: absolute;
    right: -13px;
    min-width: 225px;
    top: 47px;

    .has-beta-label {
        background: $green;
        padding: 2px $gutter-size;
        border-radius: 18px;
        font-size: $font-size--xsmall;
    }
}

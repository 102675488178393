// Sizes
$font-size--xsmall: 10px;
$font-size--midsmaller: 11px;
$font-size--smaller: 12px;
$font-size--p2: 13px;
$font-size--small: 14px;
$font-size--base: 15px;
$font-size--medium: 16px;
$font-size--header: 17px;
$font-size--large: 18px;
$font-size--xlarge: 20px;
$font-size--xlarger: 30px;
$font-size--iconLarge: 36px;
$font-size--xxlarge: 40px;

// todo: new font sizes to be used in part 2 of font update
// $font-size--xsmall: 11px;
// $font-size--smaller: 12px;
// $font-size--small: 13px;
// $font-size--base: 15px;
// $font-size--medium: 17px;
// $font-size--large: 20px;
// $font-size--xlarge: 24px;
// $font-size--xlarger: 30px;
// $font-size--iconLarge: 36px;
// $font-size--xxlarge: 30px;

// Weights
$font-weight--light: 300;
$font-weight--normal: 330;
$font-weight--bold: 600;
$font-weight--bolder: 700;
$font-weight--heavy: 800;

$font-weight--app-view--normal: 330;

// Components' font
$font-size--focus-element: 16px;

// price guidance
$pg-font-size--large: 24px;
$pg-font-size--xlarge: 32px;

%slide--transitioning {
    transition-property: transform;
    transition-timing-function: ease-in;
}

.slide {
    will-change: transform;
    transition: all 3000ms ease-in-out;
    transform: translate3d(270px, 0, 0);
}

.slide-enter-active {
    @extend %slide--transitioning;
}

.slide-exit-active {
    @extend %slide--transitioning;
}

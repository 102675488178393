@import "../base/color-variables.scss";

.sub-menu {
    user-select: none;
    background-color: $color-border--dark;
    color: $white;
    border: none;

    .sub-menu-item:hover {
        background-color: $color-text--secondary;
    }

    .link-button {
        justify-content: space-between;

        .icon {
            margin-right: 0;
        }
    }
}

// Brand
$bark: #3c3241;
$bark--dark: #1e1921;
$leaf: #72ef36;
$leaf--dark: #39781b;
$berry: #e75462;
$berry--dark: #b54343;
$blade: #0d475c;
$branch--dark: #262226;
$myrtle: #f0ece6;
$myrtle--dark: #787673;
$gtall-26629-green: #498124;
$gtall-26629-green--dark: #527435;
$gunmetal-blue: #495660;
$pagination-grey: #babfc3;
$pagination-grey--light: #bdc1cc;
$pagination-grey--dark: #635b67;
$pagination-grey--disabled: #fafbfb;
$pagination-grey--disabled-border: #b7b4b7;
$pagination-blue: #458fff;

// Jobs
$maple: #f16f3c;
$maple--dark: #b83f0e;
// Jobs

$beige: #f5f5f5;
$text-darkGrey: #5c5c5c;

$bark--80: #635b67;
$bark--60: #8a848d;
$bark--40: #b1adb3;
$bark--20: #d8d6d9;
$bark--10: #f1f0f1;

$branch--60: #938f93;
$branch--40: #b7b4b7;
$branch--20: #dbdadb;
$branch--10: #f1f1f1;
$branch--5: #f6f5f6;

$blade--70: #557e8d;
$blade--10: #e6ecee;

// Agnostic
$blue: #007fb0;
$blue--40: #99ccdf;
$blue--10: #e6f2f7;
$red: #e52815;
$red--10: #fdecea;
$yellow: #efc636;
$orange: #ff9900;
$green: #52a744;
$green--10: #eef8ed;
$white: #fff;
$lightgrey: #fafafa;
$black: #000;

// Third party
$facebook: #0084ff;
$twitter: #38a8e0;
$pinterest: #c91618;
$whatsapp: #25d366;
$messenger: #0084ff;

//price guidance
$lighter-grey: #e2e2e2;
$light-grey: #d5d5d5;
$grey: #bbbbbb;
$dark-grey: #635b67;
$foreground-default: #1f2f3b;

$new-grey: #8e969c;

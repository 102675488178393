.form-element {
    box-sizing: border-box;
    display: block;
    width: 100%;
    position: relative;
}

.form-element--inline {
    display: inline-block;
    width: auto;
}

@import "../base/color-variables";
@import "../base/colors";
@import "../base/fonts";
@import "../base/grid";

$min-height: 245px;

.tabs {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $color-background--light;

    &:before {
        box-shadow: 1px 3px 5px 0 $color-shadow--light;
        content: "";
        position: absolute;
        width: 80%;
        left: 10%;
        top: 48px;
        height: 5px;
        border-radius: 100%;
    }

    .tabs-title {
        order: 0;
        flex: 1;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        background-color: $color-background--light;
        margin-bottom: 24px;
        border: none;
        outline: none;

        &:before {
            display: block;
            content: "";
            padding: 0;
            background-color: $color-background--light;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            top: 100%;
            box-shadow: inset 3px 3px 5px $color-shadow--light;
            transition: padding 600ms cubic-bezier(0.75, -1, 0, 1.75),
                top 600ms cubic-bezier(0.75, -1, 0, 1.75);
        }

        &:focus,
        &:hover {
            text-decoration: underline;
        }

        &.is-active,
        &:active {
            font-weight: 600;

            &:before {
                top: calc(100% + -10px);
                padding: 10px;
            }
        }
    }

    .tabs-content {
        order: 1;
        flex: 0;
        display: none;
        flex-basis: 100%;
        margin: 0;
        background-color: $color-background--light;

        &.is-active {
            display: block;
        }

        &.min-height {
            min-height: $min-height;
        }
    }

    &.neutral {
        background-color: $color-background--neutral;

        .tabs-title,
        .tabs-title:before,
        .tabs-content {
            background-color: $color-background--neutral;
        }
    }

    &.newStyle {
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            top: 48px;
            height: 5px;
            border-bottom: 1px solid #dfe1e2;
            box-shadow: inherit;
            left: inherit;
            border-radius: 0;
        }

        .tabs-title {
            color: $foreground-default;

            &.is-active,
            &:active {
                border-bottom: 2px solid #5cf100;
                text-decoration: none;

                &:before {
                    top: inherit;
                    padding: 0;
                }
            }
        }
    }
}

@import "@gumtree/ui-library/src/base/colors.scss";
@import "@gumtree/ui-library/src/base/boxes.scss";
@import "@gumtree/ui-library/src/base/fonts.scss";
@import "@gumtree/ui-library/src/base/grid.scss";

.notification-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 $gutter-size--medium;
    background: $white;

    p {
        font-size: $font-size--small;
        margin: 0;
    }

    a {
        color: $blue;
    }

    .icon {
        vertical-align: baseline;
        padding-right: $gutter-size--medium;
    }
}

.notification-button-clear {
    height: $box-height;
    width: $box-height;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

@import "../base/color-variables";
@import "../base/fonts";
@import "../base/grid";

.tabs-with-buttons {
    background-color: $color-background--neutral;

    &:before {
        content: none;
    }

    .tabs-title {
        background-color: $color-disabled-background;
        border: solid 1px $color-border--light;
        border-top: none;
        border-left: none;
        color: $color-font--teritary;

        @include mq($until: m) {
            font-size: 0;
        }

        &:last-of-type {
            border-right: none;
        }

        &:before {
            content: none;
        }

        &:focus,
        &:hover {
            background-color: $color-background--lighter;
            text-decoration: none;
        }

        .icon {
            color: $color-font--teritary;
            margin-right: 6px;
        }

        &.is-active {
            background-color: $color-background--neutral;
            border-bottom: none;
            color: $color-text--primary;
        }
    }

    .tabs-content {
        background-color: white;
        width: 100%;
    }
}

@import "@gumtree/ui-library/src/base/color-variables.scss";
@import "@gumtree/ui-library/src/base/grid.scss";

.notifications {
    position: fixed;
    bottom: $gutter-size--large;
    left: 50%;
    transform: translateX(-50%);
    min-width: 250px;

    &-message {
        display: block;
        padding: 10px 22px;
        background-color: $color-background--light;
        border-radius: 25px;
        color: $color-text--primary;
        margin-bottom: $gutter-size--large;
        text-align: center;
        box-shadow: 0 0 3px $color-background--dark;
        white-space: nowrap;
    }
}

@import "./colors.scss";

// Main colors
$color-main-primary: $berry;
$color-main-primary-2024: $leaf;
$color-main-primary--dark: $berry--dark;
$color-main-secondary: $bark;
$color-main-secondary--dark: $bark--dark;
$color-main-tertiary: $blue;
$color-main-quaternary: $leaf--dark;
$color-main-gtall-2669-primary: $gtall-26629-green;
$color-main-gtall-2669-primary--dark: $gtall-26629-green--dark;

// Background
$color-background--medium: $branch--20;
$color-background--lighter: $branch--5;
$color-background--light: $branch--10;
$color-background--neutral: $white;
$color-background--dark: $branch--40;
$color-background--darker: $branch--60;
$color-background--passive: $yellow;
$color-background--striped: $blade--10;
$color-background--highlighted: $green;

// Text
$color-text--primary: $bark;
$color-text--secondary: $bark--60;
$color-text--neutral: $bark--80;
$color-text--light: $bark--40;
$color-text--highlighted: $green;
$color-text--link: $blue;
$color-text-price: $green;

// Borders
$color-border--lighter: $bark--10;
$color-border--light: $bark--20;
$color-border--medium: $bark--40;
$color-border--dark: $bark--80;
$color-border--highlighted: $green;

// Shadows
$color-shadow: $branch--20;

// Transparency
$color-shadow--light: rgba($bark--40, 0.3);
$color-overlay: rgba($branch--dark, 0.5);
$black-overlay: rgba($black, 0.8);
$black-overlay--light: rgba($black, 0.5);
$black-overlay--lighter: rgba($black, 0.2);

// Disabled items
$color-disabled-background: $branch--10;
$color-disabled-text: $bark--40;
$color-disabled-border: $branch--20;

// Fonts
$color-font--primary: $bark;
$color-font--secondary: $bark--80;
$color-font--teritary: $bark--60;
$color-font--quaternary: $bark--40;
$color-font--light: $white;

// Third party
$color-facebook: $facebook;
$color-twitter: $twitter;
$color-pinterest: $pinterest;
$color-whatsapp: $whatsapp;
$color-messenger: $messenger;

// Pagination
$color-pagination-indicator: $pagination-blue;
$color-pagination-button: $pagination-grey--dark;
$color-pagination-border: $pagination-grey;
$color-pagination-eclipse: $pagination-grey--light;
$color-pagination-disabled: $pagination-grey--disabled;
$color-pagination-disabled-border: $pagination-grey--disabled-border;
$color-pagination-active: $blade;

@import "../base/color-variables.scss";
@import "../base/z-indexes.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index--high + 2;
    background-color: $color-overlay;
}

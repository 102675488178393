/* this component makes sure that the search bar is not visible when it is closed */
@import "@gumtree/ui-library/src/base/color-variables.scss";
@import "@gumtree/ui-library/src/base/fonts.scss";
@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300..600&display=swap");

.view-container {
    position: relative;
    background-color: $color-background--neutral;
}

body {
    font-family: "Readex Pro", sans-serif;
    font-weight: $font-weight--app-view--normal;
}

body.app-view {
    font-family: "Readex Pro", sans-serif;
    font-weight: $font-weight--app-view--normal;

    min-height: 100vh;
    background-color: $color-background--neutral;

    .btn-primary {
        font-weight: $font-weight--bold;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

/* stop horizontal overflow in case an ad banner is too wide */
html {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
